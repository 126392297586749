<template>
  <div style="padding-right: 0%">
    <v-snackbar v-model="showsnackbar" color="primary" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex px-2 xs12 sm12 md12 lg12 xl12 text-center>
        <v-form ref="empform" lazy-validation>
          <v-layout wrap>
            <v-flex xs12 pt-2>
              <v-card class="mb-1">
                <v-card-title> Change Password </v-card-title>
              </v-card>
              <v-card class="mb-1">
                <v-card-title>
                  <v-row dense  justify="center"  style="width:100%!important">

                    <v-col cols="12" sm="6" class="pr-1">
                      <v-text-field
                        ref="oldPassword"
                        color="black"
                        label="Old Password"
                        outlined
                        dense
                        :rules="oldPassword"
                        v-model="employee.oldPassword"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense justify="center"  style="width:100%!important">
                    <v-col cols="12" sm="6" class="pl-1">
                      <v-text-field
                        ref="newPassword"
                        color="black"
                        label="New Password "
                        outlined
                        dense
                        :rules="newPassword"
                        v-model="employee.newPassword"
                      ></v-text-field>
                    </v-col>
                    </v-row>
                     <!-- <v-row dense justify="center"  style="width:100%!important">
                    <v-col cols="12" sm="6" class="pr-1">
                      <v-text-field
                        ref="Agentcode"
                        color="black"
                        label="Agent code"
                        outlined
                        dense
                        :rules="agentCode"
                        v-model="employee.agentCode"
                      ></v-text-field>
                    </v-col>
                     </v-row> -->
                    <v-row v-if="errmsg" justify="center" style="width:100%!important">
                      <span style="color:red">{{errmsg}}</span>
                      
                    </v-row>
                    <v-row justify="center"  style="width:100%!important">
                      
                      <v-btn
                        class="mx-2"
                        dark
                        color="primary"
                        @click="inputCheck"
                      >
                        SUBMIT
                      </v-btn>
                    </v-row>
                 
                </v-card-title>
              </v-card>
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      employee: {
        oldPassword: null,
        newPassword: null,
       
      },
      oldPassword: [(v) => !!v || " required"],
      newPassword: [(v) => !!v || " required"],

      
      showsnackbar: false,
      msg: "",
      errmsg:"",
      timeout: 2000,
    };
  },
  methods: {
    inputCheck() {
      this.msg = null;

      if (this.$refs.empform.validate()) {
        this.changePassword();
      } else {
        this.msg = "Please validate input fields";
        return;
      }
    },
    changePassword() {
      this.appLoading = true;
      // var data = {};
      // data["oldPassword"] = this.oldPassword;
      // data["newPassword"] = this.newPassword;
      axios({
        method: "POST",
        url: "/password/change",
        headers: {
          "token": localStorage.getItem("token"),
        },
        data: this.employee,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) this.$router.go(-1);
          else this.msg = response.data.msg;
        })
        .catch((e) => {
          this.ServerError = true;
          console.log(e)
        });
    },
  },
};
</script>
<style >

.v-text-field--outlined.v-input--dense .v-label {
  top: 8px !important;
  font-size: 11px !important;
  font-weight: bold;
  color: black !important;
}
.v-text-field input {
  color: black !important;
}
</style>
